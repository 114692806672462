import React, { FC } from 'react';
import cn from 'classnames';
import styles from './PageTitle.module.less';

interface PageTitleProps {
  className?: string;
}
export const PageTitle: FC<PageTitleProps> = ({ className, children }) => {
  return <h1 className={cn(styles.container, className)}>{children}</h1>;
};
