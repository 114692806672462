import { getAuthToken } from 'common/requests';
import { map } from 'lodash';

import { ENV } from './constants/env';

const BASE_URL = ENV.FRONTEND_BASE_URL || '/';

const routes = {
  root: `${BASE_URL}`,
  offers: `${BASE_URL}offers`,
  offer: (id: number | string) => `${BASE_URL}offers/${id}`,
  urgentOffers: `${BASE_URL}offers/urgent`,
  responses: `${BASE_URL}responses`,
  rfqs: `${BASE_URL}rfqs`,
  rfqsResponses: `${BASE_URL}rfqs_responses`,
  rfqOffer: (id: number | string) => `${BASE_URL}rfqs/${id}`,

  orders: `${BASE_URL}orders`,
  order: (id?: number | string | null) => (id ? `${BASE_URL}orders/${id}` : undefined),

  support: `${BASE_URL}support`,

  profileDetails: `${BASE_URL}profile/details`,
  profileSignIn: `${BASE_URL}profile/sign_in`,
  profileSignUp: `${BASE_URL}profile/sign_up`,
  profileRemoteSignIn: `${BASE_URL}profile/remotly/sign_in/:token`,
  profileInitResetPassword: `${BASE_URL}profile/init_reset_password`,
  profileResetPassword: `${BASE_URL}profile/reset_password/:token`,
  profileUnsubscribe: `${BASE_URL}profile/unsubscribe`,
  profileConfirmEmail: `${BASE_URL}profile/verify/:token`,
  profileOnboarding: `${BASE_URL}profile/onboarding`,
  calendar: `${BASE_URL}calendar`,

  invoices: `${BASE_URL}invoices`,

  page404: `${BASE_URL}404`,
  page503: `${BASE_URL}503`,

  uploadFile: (): string => `${ENV.CDN_URL}/partners/attached_files?token=${getAuthToken()}`,

  fileInfo: (uuidIds: string[]): string => {
    const query = uuidIds.map((i) => `uuid_ids[]=${i}`).join('&');

    return `${ENV.CDN_URL}/attached_files?${query}`;
  },

  offerZipUrl: (offerId: number | string): string =>
    `${ENV.CDN_URL}/partners/job_offers/${offerId}/download_zip?token=${getAuthToken()}`,

  orderZipUrl: (orderId: number | string): string =>
    `${ENV.CDN_URL}/partners/provider_orders/${orderId}/download_zip?token=${getAuthToken()}`,

  orderPdfUrl: (
    orderId: number | string,
    type:
      | 'download_po_pdf'
      | 'download_td_pdf'
      | 'download_dc_pdf'
      | 'download_dn_pdf'
      | 'download_tl_pdf'
      | 'download_ci_pdf'
      | 'download_qc_template_pdf'
      | 'download_labels',
  ): string => `${ENV.CDN_URL}/partners/provider_orders/${orderId}/${type}?token=${getAuthToken()}`,

  orderQcPdfUrl: (orderId: number | string): string =>
    `${ENV.CDN_URL}/partners/provider_orders/${orderId}/download_qc_pdf?token=${getAuthToken()}`,

  downloadAttachedFile: (uuidId: string): string => `${ENV.CDN_URL}/download_file/${uuidId}`,

  downloadAttachedFiles: (uuidIds: string[], filename?: string): string =>
    `${ENV.CDN_URL}/download_files?filename=${filename || ''}&${map(uuidIds, (id) => `uuid_ids[]=${id}`).join('&')}`,

  downloadRfqQuoteTemplate: (rfqId: number) => `${ENV.CDN_URL}/partners/rfqs/${rfqId}/download?token=${getAuthToken()}`,

  downloadRfqAllDrawings: (rfqId: number) =>
    `${ENV.CDN_URL}/partners/rfqs/${rfqId}/download_zip?token=${getAuthToken()}`,
};

export default routes;

export const makeAbsoluteRoute = (route: string) => {
  if (ENV.FRONTEND_BASE_URL) {
    const base =
      ENV.FRONTEND_BASE_URL[ENV.FRONTEND_BASE_URL.length - 1] === '/'
        ? ENV.FRONTEND_BASE_URL.substring(0, ENV.FRONTEND_BASE_URL.length - 1)
        : ENV.FRONTEND_BASE_URL;

    return `${base}${route.replace(base, '')}`;
  } else {
    return route;
  }
};
